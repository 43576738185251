import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import CyberImage from "../../assets/images/services/cyber.webp"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Custom Cybersecurity Solutions - Network Security Solutions"
                description="TEQT is a cutting-edge cyber security consulting firm that offers its services to companies all around the world." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={CyberImage} alt="Custom Software Development" className="image" />
                    </div>
                    <div className="right-sec">
                        <h1>Data Analytics Solutions</h1>
                        <p className="mt-2">
                            Our Data Analytics Solutions service is designed to help businesses unlock the full potential of their data, enabling informed decision-making, driving growth, and gaining a competitive edge in their industries.
                        </p>
                    </div>
                </div>
                <div className="service-sub-container">
                    <h3 className="mt-3">Key Features</h3>
                    <ul>
                        <li className="mt-2">Network Security: Protect your network infrastructure from unauthorized access and malicious attacks.</li>
                        <li className="mt-2">Data Encryption: Secure sensitive data with encryption technologies to prevent unauthorized access.</li>
                        <li className="mt-2">Endpoint Protection: Secure devices and endpoints against malware, ransomware, and other cyber threats.</li>
                        <li className="mt-2">Incident Response: Develop and implement effective incident response plans to minimize the impact of security breaches.</li>
                        <li className="mt-2">Security Training: Educate your employees on cybersecurity best practices to reduce the risk of human error.</li>
                    </ul>
                    <h3 className="mt-3">How It Works</h3>
                    <p className="mt-2">
                        Our Cyber Security solutions begin with a comprehensive assessment of your organization's security posture and vulnerabilities. Based on our findings, we develop a customized security strategy and implementation plan tailored to your specific needs and compliance requirements. Our team of experienced security professionals then works closely with you to implement the solution, monitor for threats, and respond to security incidents effectively.
                    </p>
                    <h3 className="mt-3">Why Choose Us?</h3>
                    <p className="mt-2">
                        With years of experience in Cyber Security and a proven track record of protecting businesses from cyber threats, our team has the expertise and resources to safeguard your organization's critical assets and infrastructure. Whether you're a small business or a large enterprise, we're committed to providing you with the highest level of security to ensure peace of mind and business continuity.
                    </p>
                </div>

                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query CyberSecurityPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
